import Link from 'next/link';
import React, { useState } from 'react';
import { hero_5_data } from '../../data/coverflow_data';
import { useTranslation } from 'react-i18next';
import { SearchBar } from '../ui/SearchBar';

const Hero_5 = () => {
	const {t} = useTranslation()
	const [search, setSearch] = useState("");
	return (
		<>
			{/* <!-- Hero --> */}
			<section className="relative py-20 md:pt-32 hero-gradient">
				<picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
					<img src="/images/gradient.jpg" alt="gradient" className="h-full" />
				</picture>
				<picture className="pointer-events-none absolute inset-0 -z-10 hidden dark:block">
					<img src="/images/gradient_dark.jpg" alt="gradient dark" className="h-full" />
				</picture>

				<div className="h-full px-6 xl:px-20">
					<div className="grid h-full items-center gap-4 lg:grid-cols-12">
						<div className="col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start md:py-20 xl:col-span-5 xl:pl-[20%] xl:pr-[10%]">
							
							<h1 className="text-jacarta-700 font-bold font-display mb-1 text-center text-5xl dark:text-white md:text-left lg:text-6xl xl:text-7xl">
              Influencer Marketing<span className="animate-gradient" style={{animation: 'fadeIn 1s'}}> WEB3</span>
            </h1>
			<p
              className="dark:text-jacarta-100 mb-8 text-center text-lg md:text-left"
              style={{ textTransform: "none" }}
            >
              {t("heroSubtitle")}
            </p>
			<div className="mx-auto d-none hidden  mb-6 w-full text-center md:text-left">
                {/* <!-- Search --> */}
                <SearchBar
				onSearch={setSearch}
                  label={
                    "Search influencers by any keyword, @username, category"
                  }
                />

                <span className="dark:text-jacarta-200 text-accent">
                  Popular searches:&nbsp;
                </span>
                <a
                  href={`/influencers?search=${"beauty"}`}
                  //  onClick={() => Router.push(`/influencers?search=${'beauty'}`)}
                >
                  <span className="text-accent-light font-bold hover:text-jacarta-700 dark:hover:text-white transition-all">
                    beauty,&nbsp;
                  </span>
                </a>
                <a href={`/influencers?search=${"art"}`}>
                  <span className="text-accent-light font-bold hover:text-jacarta-700 dark:hover:text-white transition-all">
                    art,&nbsp;
                  </span>
                </a>
                <a href={`/influencers?search=${"fitness"}`}>
                  <span className="text-accent-light font-bold hover:text-jacarta-700 dark:hover:text-white transition-all">
                    fitness&nbsp;
                  </span>
                </a>
              </div>
							<div className="flex space-x-4 hidden">
								{/* <Link href="/create">
									<a className="w-36 rounded-full bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark">
										Upload
									</a>
								</Link> */}
								<Link href={`/influencers?search=${search}`}>
									<a className="w-36 rounded-full bg-white py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume">
										Explore
									</a>
								</Link>
							</div>
						</div>

						{/* <!-- Hero images --> */}
						<div className="relative col-span-6 xl:col-span-6 xl:col-start-7">
							{/* <img
								src="/images/hero/badge.png"
								className="absolute top-0 z-10 -ml-16 animate-spin-slow md:top-[12%]"
								alt=""
							/> */}

<img
                src="/images/hero/3D_elements.png"
                alt="3D_elements"
                className="animate-fly absolute top-0 md:-right-[10%] pointer-events-none" style={{zIndex:8}}
              />
							<div className="md:flex md:space-x-6 xl:space-x-12">
								{hero_5_data.map((item, index) => {
									const { id, img, title, authorImage, authorName, subItem } = item;
									const itemLink = ``
									return (
										<div
											className={
												index === 0
													? 'mb-6 md:flex md:w-1/2 md:items-center'
													: 'space-y-6 md:w-1/2 xl:space-y-12'
											}
											key={id}
										>
											<article>
												<div className="block overflow-hidden rounded-2.5xl bg-white shadow-md transition-shadow hover:shadow-lg dark:bg-jacarta-700">
													<figure className="relative">
														<Link href={`${itemLink}`}>
															<a>
																<img
																	src={img}
																	alt="item 1"
																	className="w-full object-cover max-h-[450px]"
																	height="437"
																	width="406"
																/>
															</a>
														</Link>
													</figure>
													<div className="p-6 hidden">
														<div className="flex">
																<Link href={`${itemLink}`}>
																<a className="shrink-0">
																	<img
																		src={authorImage}
																		alt="avatar"
																		className="mr-4 h-10 w-10 rounded-full"
																	/>
																</a>
															</Link>
															<div>
																<Link href={`${itemLink}`}>
																	<a className="block">
																		<span className="font-display text-lg leading-none text-jacarta-700 hover:text-accent dark:text-white">
																			{title}
																		</span>
																	</a>
																</Link>
																	<Link href={`${itemLink}`}>
																	<a className="text-2xs text-accent">{authorName}</a>
																</Link>
															</div>
														</div>
													</div>
												</div>
											</article>

											{subItem &&
												subItem.map(({ id, img, title, authorImage, authorName }) => {
													const itemLink = ``
													return (
														<div className="md:w-3/4" key={id}>
															<article>
																<div className="block overflow-hidden rounded-2.5xl bg-white shadow-md transition-shadow hover:shadow-lg dark:bg-jacarta-700">
																	<figure className="relative">
																		<Link  href={`${itemLink}`}>
																			<a>
																				<img
																					src={img}
																					alt="item 1"
																					className="w-full object-cover"
																					height="437"
																					width="406"
																				/>
																			</a>
																		</Link>
																	</figure>
																	<div className="p-6 hidden">
																		<div className="flex">
																				<Link href={`${itemLink}`}>
																				<a className="shrink-0">
																					<img
																						src={authorImage}
																						alt="avatar"
																						className="mr-4 h-10 w-10 rounded-full"
																					/>
																				</a>
																			</Link>
																			<div>
																				<Link href={`${itemLink}`}>
																					<a className="block">
																						<span className="font-display text-lg leading-none text-jacarta-700 hover:text-accent dark:text-white">
																							{title}
																						</span>
																					</a>
																				</Link>
																					<Link href={`${itemLink}`}>
																					<a className="text-2xs text-accent">{authorName}</a>
																				</Link>
																			</div>
																		</div>
																	</div>
																</div>
															</article>
														</div>
													);
												})}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- end hero --> */}
		</>
	);
};

export default Hero_5;
