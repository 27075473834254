export const coverflow_data = [
  {
    img: "/images/products/item_13_lg.jpg",
    title: "Etherium NFT Launching Lab",
    authorImage: "/images/avatars/avatar_18_rounded.gif",
    authorName: "051_Hart",
    id: "0Etherium NFT Launching Lab",
  },
  {
    img: "/images/products/item_16_lg.jpg",
    title: "Oceania \\\\ OVERSEER 017",
    authorImage: "/images/avatars/avatar_22_rounded.jpg",
    authorName: "MadeByM1KE",
    id: "1Oceania \\\\ OVERSEER 017",
  },
  {
    img: "/images/products/item_12_lg.jpg",
    title: "Light Bars",
    authorImage: "/images/avatars/avatar_17_rounded.jpg",
    authorName: "Wow Frens",
    id: "2Light Bars",
  },
  {
    img: "/images/products/item_14_lg.jpg",
    title: "Amazing NFT art",
    authorImage: "/images/avatars/avatar_19_rounded.jpg",
    authorName: "Lila Spacex",
    id: "3Amazing NFT art",
  },
  {
    img: "/images/products/item_15_lg.jpg",
    title: "SwagFox#133",
    authorImage: "/images/avatars/avatar_20_rounded.jpg",
    authorName: "Bored Bunny",
    id: "4SwagFox#133",
  },
  {
    img: "/images/products/item_13_lg.jpg",
    title: "Etherium NFT Launching Lab",
    authorImage: "/images/avatars/avatar_18_rounded.gif",
    authorName: "051_Hart",
    id: "5Etherium NFT Launching Lab",
  },
  {
    img: "/images/products/item_16_lg.jpg",
    title: "Oceania \\\\ OVERSEER 017",
    authorImage: "/images/avatars/avatar_22_rounded.jpg",
    authorName: "MadeByM1KE",
    id: "6Oceania \\\\ OVERSEER 017",
  },
  {
    img: "/images/products/item_12_lg.jpg",
    title: "Light Bars",
    authorImage: "/images/avatars/avatar_17_rounded.jpg",
    authorName: "Wow Frens",
    id: "7Light Bars",
  },
  {
    img: "/images/products/item_14_lg.jpg",
    title: "Amazing NFT art",
    authorImage: "/images/avatars/avatar_19_rounded.jpg",
    authorName: "Lila Spacex",
    id: "8Amazing NFT art",
  },
  {
    img: "/images/products/item_15_lg.jpg",
    title: "SwagFox#133",
    authorImage: "/images/avatars/avatar_20_rounded.jpg",
    authorName: "Bored Bunny",
    id: "9SwagFox#133",
  },
  {
    img: "/images/products/item_13_lg.jpg",
    title: "Etherium NFT Launching Lab",
    authorImage: "/images/avatars/avatar_18_rounded.gif",
    authorName: "051_Hart",
    id: "10Etherium NFT Launching Lab",
  },
  {
    img: "/images/products/item_16_lg.jpg",
    title: "Oceania \\\\ OVERSEER 017",
    authorImage: "/images/avatars/avatar_22_rounded.jpg",
    authorName: "MadeByM1KE",
    id: "11Oceania \\\\ OVERSEER 017",
  },
  {
    img: "/images/products/item_12_lg.jpg",
    title: "Light Bars",
    authorImage: "/images/avatars/avatar_17_rounded.jpg",
    authorName: "Wow Frens",
    id: "12Light Bars",
  },
  {
    img: "/images/products/item_14_lg.jpg",
    title: "Amazing NFT art",
    authorImage: "/images/avatars/avatar_19_rounded.jpg",
    authorName: "Lila Spacex",
    id: "13Amazing NFT art",
  },
  {
    img: "/images/products/item_15_lg.jpg",
    title: "SwagFox#133",
    authorImage: "/images/avatars/avatar_20_rounded.jpg",
    authorName: "Bored Bunny",
    id: "14SwagFox#133",
  },
];

export const hero_5_data = [
  {
    img: "https://ecoinomy.eu/wp-content/uploads/2023/02/bitcoin_creator_satoshi_nakamoto_v01.jpg",
    title: "",
    authorImage: "https://ecoinomy.eu/wp-content/uploads/2023/02/bitcoin_creator_satoshi_nakamoto_v01.jpg",
    authorName: " ",
    id: "fXwQA16G2JPs5PpJ1HEW3V2DKxO2",
  },
  {
    img: "https://cryptoslate.com/wp-content/uploads/2024/03/anatoly-yakovenko-768x403.jpg",
    title: "",
    authorImage: "https://cryptoslate.com/wp-content/uploads/2024/03/anatoly-yakovenko-768x403.jpg",
    authorName: "@",
    id: "62c7b4c4-c3ed-493f-836b-7819b1921f37",

    subItem: [
      {
        img: "https://www.brifing.com.tr/wp-content/uploads/2023/01/vitalik-buterin.jpg",
        title: "",
        authorImage: "https://www.brifing.com.tr/wp-content/uploads/2023/01/vitalik-buterin.jpg",
        authorName: "",
        id: "ede55818-b551-4728-8af4-aff6661f843c",
      },
    ],
  },
];
